<script>
import adminProfileStructure from '@components/adminProfileStructure'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {mapState} from "vuex";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {
      title: 'Admin',
  },
  components: {PaginationRow, adminProfileStructure, quillEditor},
  mixins: [PaginatedDataMixin],
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    })
  },

  data() {
    return {
      article:{
        title: null,
        content: null,
        articleCategoryId: null
      },
      categories: [],
      action: 'CREATE'
    }
  },
  created: function(){
      this.api.url = "/library";

      this.fetchCategories();
      this.getData();
  },
  methods:{

      fetchCategories(){
          this.$axios.get(`/library/categories`, ).then(result => {
              this.categories = result.data;
          }).catch(e => {
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των κατηγοριών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
          });
      },

      addArticle(){

          if(this.action === 'CREATE'){
              this.$axios.post(`/library`, this.article).then(result => {
                  this.getData();
                  this.$refs['add-new'].hide();
              }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ενημερώσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          }else{
              this.$axios.put(`/library/${this.article.id}`, {article: this.article}).then(result => {
                  this.getData();
                  this.$refs['add-new'].hide();
              }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ενημερώσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          }

      },
      deleteArticle(id){
          let message = {
              title: 'Θέλετε σίγουρα να διαγράψετε το άρθρο;',
              body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε το άρθρο.'
          };
          this.$dialog.confirm(message, {
              loader: true,
              okText: 'Διαγραφή',
              cancelText: 'Ακύρωση',
          }).then((dialog) => {
              this.$axios.delete(`/library/${id}`).then(result => {
                  this.getData();
                  dialog.close();
              }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ενημερώσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          });
      },

      openModal(){
          this.action = 'CREATE';
          this.article = {
              title: null,
              content: null,
              articleCategoryId: null
          };
          this.$refs["add-new"].show();
      },

      editArticle(item){
          this.action = 'UPDATE';
          this.article = item;
          this.$refs['add-new'].show();
      },
  }
}
</script>
<template>
<adminProfileStructure :user="user">
  <template v-slot:admin-structure-content>

    <b-card-title>
          Λίστα άρθρων
    </b-card-title>
    <b-row>
        <b-col>
            <b-button variant="outline-primary" @click="openModal">Προσθήκη άρθρου</b-button>
        </b-col>
        <b-col>
            <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
                <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400"/>
            </div>
        </b-col>
    </b-row>


    <div v-if="api.rows.length > 0" class="mt-5 mb-5">
        <b-card-group deck>
            <b-card v-for="art in api.rows" :key="art.id" class="shadow m-2" style="min-width:25%">
                <b-card-title>{{art.title}}</b-card-title>
                <b-card-text class="float-right">
                    <b-button variant="warning" size="xs" class="mr-2" @click="editArticle(art)"><i class="fa fa-edit"></i></b-button>
                    <b-button variant="danger" size="xs" @click="deleteArticle(art.id)"><i class="fa fa-trash"></i></b-button>
                </b-card-text>
            </b-card>
        </b-card-group>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>

    </div>

    <b-modal id="add-notification" ref="add-new" title="Προσθήκη άρθρου" hide-footer body-class="p-3">
      <b-form @submit.prevent="addArticle">
        <div class="row">
            <div class="col-md-12">
                <b-form-group id="title" label="Τίτλος άρθρου" label-for="title" >
                    <b-form-input id="title" v-model="article.title" type="text"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-12 mb-3">
                <label>Επιλογή κατηγορίας</label>
                <b-form-select v-model="article.articleCategoryId" label-for="category" >
                    <option v-for="category of categories" :key="category.id" :value="category.id">
                        {{category.parentCategoryId === null ? '' : ' &mdash;'}} {{category.name}}
                    </option>
                </b-form-select>
            </div>
            <div id="content-admin" class="col-md-12" >
                <quill-editor
                        ref="noteEditor"
                        v-model="article.content"
                        :options="quillOptions"/>
            </div>


            <div class="text-right mt-2">
              <save-button type="submit" :text="action === 'CREATE' ? 'Προσθήκη άρθρου' : 'Επεξεργασία άρθρου'"></save-button>
            </div>
        </div>
      </b-form>
    </b-modal>


  </template>

</adminProfileStructure>
</template>
<style lang="scss">
#content-admin{
    .ql-editor{
        height:200px!important;
    }
}
</style>
